<template>
  <Toast />
  <ConfirmDialog></ConfirmDialog>
  <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer">VENTAS</a>
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3 active">DETALLE DE VENTAS</span>
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-12 lg:px-12 border-2">
    <div
      class="flex align-items-center flex-column lg:justify-content-center lg:flex-row"
    >
      <h4>
        <strong>DETALLE DE VENTA: #{{ venta.id }} - </strong>
        <span
          v-if="venta.tipo_pago_nombre == 'PAGO AL CONTADO'"
          style="color: #28a745"
        >
          {{ venta.tipo_pago_nombre }}
        </span>
        <span
          v-else-if="venta.tipo_pago_nombre == 'PAGO AL CRÉDITO'"
          style="color: #dc3545"
        >
          {{ venta.tipo_pago_nombre }}
        </span>
      </h4>
    </div>
    <div class="grid style_card" style="background-color: #f8f9fa">
      <div class="col-4">
        <p class="mb-0">
          <strong>RAZ&Oacute;N SOCIAL: </strong>
          <span>{{ venta.razon_social }}</span>
        </p>
        <p class="mb-0">
          <strong>NIT/CI: </strong> <span>{{ venta.nit }}</span>
        </p>
      </div>
      <div class="col-4">
        <p class="mb-0">
          <strong>FECHA VENTA: </strong>
          <span>{{ venta.fecha_venta }}</span>
        </p>
        <p class="mb-0">
          <strong>VENDEDOR: </strong>
          <span>{{ venta.nombre_usuario_caja }}</span>
        </p>
      </div>
      <div class="col-4">
        <p class="mb-0">
          <strong>ESTADO: </strong>
          <span
            v-if="venta.estado == 1"
            style="
              background-color: #28a745;
              color: white;
              padding: 2px;
              border-radius: 5px;
              font-weight: bold;
            "
            class="badge"
          >
            {{ venta.estado_texto }}</span
          >
          <span
            v-else
            style="
              background-color: #dc3545;
              color: white;
              padding: 2px;
              border-radius: 5px;
              font-weight: bold;
            "
            class="badge"
            >{{ venta.estado_texto }}</span
          >
        </p>
        <p class="mb-0">
          <strong>RECIBO/FACTURA: </strong>
          <span>{{ venta.nrofactura }}</span>
        </p>
      </div>
    </div>
    <div class="grid mt-2">
      <DataTable
        ref="ventas"
        :value="productos"
        class="col-12 p-datatable-sm"
        responsiveLayout="scroll"
        stripedRows
        showGridlines
        selectionMode="single click"
        editMode="cell"
        @cell-edit-complete="onChangeEditandoCelda"
      >
        <Column field="codigo_interno" header="C&Oacute;DIGO">
          {{ producto.codigo_interno ?? "-" }}
        </Column>
        <Column field="fabrica_nombre" header="LABORATORIO">
          {{ producto.fabrica_nombre ?? "-" }}
        </Column>
        <Column
          field="descripcion"
          header="PRODUCTO/DESCRIPCIÓN"
          style="font-weight: bold"
        >
          {{ producto.descripcion }}
        </Column>
        <Column field="unidad_medida_nombre" header="U.MEDIDA"> </Column>
        <Column
          field="pivot.fecha_vence"
          header="FECHA VENC."
          style="text-align: center"
        >
          <template #body="slotProps">
            {{
              slotProps.data.pivot.fecha_vence == "1905-03-14"
                ? "-"
                : formatoFecha(slotProps.data.pivot.fecha_vence)
            }}
          </template>
        </Column>
        <Column
          field="pivot.cantidad"
          header="CANTIDAD"
          style="text-align: right"
        >
          <template #body="slotProps">
            <span style="font-weight: bold; font-size: 1rem">
              {{ convertirNumeroGermanicFormat(slotProps.data.pivot.cantidad) }}
            </span>
          </template>
        </Column>
        <Column
          v-if="acciones_eliminar"
          class="bg-green-200"
          field="cantidad_devolucion"
          header="CANT.DEVOL."
          style="font-weight: bold; text-align: right"
        >
          <template #editor="{ data }">
            <InputNumber
              :inputClass="'text-right'"
              :min="0"
              locale="de-ED"
              v-model="data.cantidad_devolucion"
              v-tooltip.top="'Cantidad a devolver'"
              class="p-inputtext-sm"
            />
          </template>
        </Column>
        <Column field="pivot.precio" header="PRECIO" style="text-align: right">
          <template #body="slotProps">
            <span style="font-weight: bold; font-size: 1rem">
              {{ convertirNumeroGermanicFormat(slotProps.data.pivot.precio) }}
            </span>
          </template>
        </Column>
        <Column
          field="pivot.descuento_neto"
          header="DESC."
          style="text-align: right"
        >
          <template #body="slotProps">
            <span style="font-weight: bold; font-size: 1rem">
              {{
                convertirNumeroGermanicFormat(
                  slotProps.data.pivot.descuento_neto ?? 0
                )
              }}
            </span>
          </template>
        </Column>
        <Column header="TOTAL" style="text-align: right">
          <template #body="slotProps">
            <span style="font-weight: bold; font-size: 1rem">
              {{
                convertirNumeroGermanicFormat(
                  slotProps.data.pivot.cantidad * slotProps.data.pivot.precio -
                    slotProps.data.pivot.descuento_neto
                )
              }}
            </span>
          </template>
        </Column>
      </DataTable>
    </div>
    <table
      class="table table-bordered table-striped"
      width="100%"
      cellspacing="0"
      style="font-size: 1.3rem"
    >
      <tbody class="text-right">
        <tr>
          <td class="text-right">
            <strong>SUBTOTAL Bs: </strong>
            {{ convertirNumeroGermanicFormat(subtotal) }}
          </td>
        </tr>
        <tr>
          <td class="text-right">
            <strong>DESCUENTO Bs: </strong>
            <span class="text-green-500 font-bold">
              {{ convertirNumeroGermanicFormat(venta.descuento) }}
            </span>
          </td>
        </tr>
        <tr v-if="venta.tipo_pago_id == 2">
          <td>
            <strong>A CUENTA Bs:</strong>
            {{ convertirNumeroGermanicFormat(venta.total_pagos) }}
          </td>
        </tr>
        <tr>
          <td>
            <strong>
              MONTO
              {{ venta.tipo_pago_id == 1 ? "CANCELAR" : "DEBE" }} Bs:
            </strong>
            {{
              convertirNumeroGermanicFormat(
                venta.tipo_pago_id == 1
                  ? venta.total_cancelar
                  : venta.total_cancelar - venta.total_pagos
              )
            }}
          </td>
        </tr>
      </tbody>
    </table>
    <div class="grid mt-3">
      <div class="field col-12 md:col-6">
        <div
          class="flex align-items-end justify-content-start"
          v-if="'Venta Anular' in auth.user.permissions"
        >
          <Button
            v-if="venta.estado == 1 && 'Venta Anular' in auth.user.permissions"
            icon="pi pi-trash"
            :class="{
              'p-button-danger': !acciones_eliminar,
              'p-button-secondary': acciones_eliminar,
            }"
            v-tooltip.top="'Acciones de Anulación'"
            @click="
              acciones_eliminar == false
                ? (acciones_eliminar = true)
                : (acciones_eliminar = false)
            "
          />
          <Button
            v-if="acciones_eliminar"
            icon="pi pi-trash"
            label="ANULAR VENTA"
            class="p-button-danger mr-1 ml-1"
            v-tooltip.top="'Anular Venta'"
            @click="anularVenta(venta.id)"
            :loading="anulando"
          />
          <Button
            v-if="acciones_eliminar && venta.tipo_pago_id == 2"
            icon="pi pi-check"
            label="DEVOLVER SELECCIONADOS"
            class="p-button-info"
            v-tooltip.top="'Devolver Seleccionados'"
            @click="devolverProductosSeleccionados(venta.id)"
          />
          <!--  :loading="devolviendo" -->
        </div>
      </div>

      <div class="field col-12 md:col-6">
        <div class="flex align-items-end justify-content-end">
          <Button
            @click="goBack"
            class="p-button-info"
            v-tooltip.top="'Volver a Ventas'"
          >
            <i class="pi pi-arrow-left"></i>&nbsp;ATR&Aacute;S
          </Button>
          <Button
            :label="
              venta.facturado == 1 ? 'IMPRIMIR FACTURA' : 'IMPRIMIR VENTA'
            "
            icon="pi pi-print"
            class="p-button-danger ml-2"
            v-tooltip.top="'Imprimir PDF'"
            @click="imprimirVenta(venta.id)"
            :disabled="imprimiendo"
            :loading="imprimiendo"
          >
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VentaService from "@/service/VentaService";
import { useAuth } from "@/stores";
export default {
  data() {
    return {
      venta: {},
      venta_id: this.$route.params.id,
      productos: [],
      imprimiendo: false,
      acciones_eliminar: false,
      anulando: false,
      devolviendo: false,
    };
  },
  ventaService: null,
  auth: null,
  created() {
    this.ventaService = new VentaService();
    this.auth = useAuth();
  },
  mounted() {
    this.cargarVenta();
  },
  computed: {
    subtotal() {
      let subtotal = 0;
      this.productos.forEach((producto) => {
        subtotal += producto.pivot.precio * producto.pivot.cantidad;
      });
      return subtotal;
    },
  },
  methods: {
    formatoFecha(fecha) {
      let fecha_array = fecha.split("-");
      return `${fecha_array[2]}/${fecha_array[1]}/${fecha_array[0]}`;
    },
    devolverProductosSeleccionados(venta_id) {
      let productos = this.productos.filter(
        (producto) => producto.cantidad_devolucion > 0
      );
      if (productos.length == 0) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "No hay productos seleccionados para devolver",
          life: 3000,
        });
        return;
      }
      this.$confirm.require({
        header: "CONFIRMACIÓN",
        message: "¿ESTÁS SEGURO DE DEVOLVER LOS PRODUCTOS SELECCIONADOS?",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Si, Devolver",
        rejectLabel: "No, Cancelar",
        acceptClass: "p-button-primary p-button-lg",
        rejectClass: "p-button-danger p-button-lg",
        acceptIcon: "pi pi-save",
        rejectIcon: "pi pi-times",
        accept: () => {
          this.devolviendo = true;
          let datos = {
            venta_id: venta_id,
            productos: productos,
          };
          this.ventaService
            .devolverProductosSeleccionados(datos)
            .then((response) => {
              if (response.success == false) {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.message,
                  life: 3000,
                });
                this.devolviendo = false;
                return;
              } else {
                this.$toast.add({
                  severity: "success",
                  summary: "Éxito",
                  detail: response.message,
                  life: 3000,
                });
                this.cargarVenta();
                this.acciones_eliminar = false;
                this.devolviendo = false;
              }
            });
        },
      });
    },
    anularVenta(id) {
      this.$confirm.require({
        header: "CONFIRMACIÓN",
        message: "¿ESTÁS SEGURO DE ANULAR ESTA VENTA?",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Si, Anular",
        rejectLabel: "No, Cancelar",
        acceptClass: "p-button-primary p-button-lg",
        rejectClass: "p-button-danger p-button-lg",
        acceptIcon: "pi pi-save",
        rejectIcon: "pi pi-times",
        accept: () => {
          this.anulando = true;
          this.ventaService.anularVenta(id).then((response) => {
            if (response.status == 200) {
              this.$toast.add({
                severity: "success",
                summary: "Éxito",
                detail: response.mensaje,
                life: 3000,
              });
              this.cargarVenta();
              this.anulando = false;
              this.acciones_eliminar = false;
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: response.mensaje,
                life: 3000,
              });
              this.anulando = false;
              this.acciones_eliminar = false;
            }
          });
        },
      });
    },
    onChangeEditandoCelda(e) {
      let { data, newValue, field } = e;
      data[field] = newValue;
      if (field == "cantidad_devolucion") {
        if (newValue > data.pivot.cantidad) {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "La cantidad a devolver no puede ser mayor a la cantidad",
            life: 3000,
          });
          data.cantidad_devolucion = parseFloat(data.pivot.cantidad);
        }
      }
    },
    imprimirVenta(id) {
      if (this.imprimiendo) {
        return;
      }
      this.imprimiendo = true;
      this.ventaService.imprimirVenta(id).then(() => {
        this.imprimiendo = false;
      });
    },
    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
      }).format(numero);
    },
    cargarVenta() {
      this.ventaService.getVenta(this.venta_id).then((response) => {
        this.venta = response.venta;
        this.productos = this.venta.productos;
      });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/demo/badges.scss";

.p-invalid {
  color: red;
}
.style_card {
  border: 1px solid #000000;
  border-bottom: 3px solid #000000;
  padding: 0px;
  background-color: #f8f9fa;
  border-radius: 5px;
}
</style>
